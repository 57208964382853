<template>
  <v-tooltip
    right
    :open-on-hover="false"
    :open-on-focus="false"
  >
    <template v-slot:activator="{attr, on}">
      <v-btn
        v-bind="attr"
        small
        color="primary"
        outlined
        icon
        v-on="on"
        @click="copyText"
      >
        <v-icon small>
          {{ icons.mdiClipboardTextOutline }}
        </v-icon>
      </v-btn>
    </template>
    <span>Copied!</span>
  </v-tooltip>
</template>

<script>
import { mdiClipboardTextOutline } from '@mdi/js'

export default {
  props: {
    text: { default: '', type: String },
  },

  data: () => ({
    icons: { mdiClipboardTextOutline },
  }),
  methods: {
    copyText() {
      navigator.clipboard.writeText(this.text)
    },
  },
}
</script>

<style>

</style>
