var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"dense":"","text":"","type":"success","icon":_vm.icons.mdiBrightnessPercent,"border":"right"}},[_c('div',[_vm._v("Referral rate")]),_c('h3',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.referralInfo.rate)+" ")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"dense":"","text":"","type":"info","icon":_vm.icons.mdiWallet,"border":"right"}},[_c('div',[_vm._v("All-time profit")]),_c('h3',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.referralInfo.profit)+" ")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"dense":"","text":"","type":"warning","icon":_vm.icons.mdiWallet,"border":"right"}},[_c('div',[_vm._v("Referral link")]),_c('h4',{staticClass:"mt-2"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.referralInfo.referral_link))]),_c('CopyTextBtn',{attrs:{"text":_vm.referralInfo.referral_link}})],1)])],1)],1)],1),_c('v-card-title',[_vm._v("Referrals list")]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","mobile-breakpoint":0,"loading-text":"Loading referrals ...","loading":_vm.loading,"items":_vm.referralList,"headers":_vm.tableHeaders,"server-items-length":_vm.total,"options":_vm.tableOptions,"footer-props":{
        'items-per-page-options': _vm.pages,
        'show-first-last-page': true,
        'show-current-page': true,
      }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
      var item = ref.item;
      var value = ref.value;
return [_vm._t("default",function(){return [_c('span',[_vm._v(_vm._s(value.slice(0,value.length-7).split("T").join(" ")))])]})]}},{key:"footer",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('TableFooter',_vm._g(_vm._b({},'TableFooter',props,false),on))]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }