<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-alert
            dense
            text
            type="success"
            :icon="icons.mdiBrightnessPercent "
            border="right"
          >
            <div>Referral rate</div>
            <h3 class="mt-2">
              {{ referralInfo.rate }}
            </h3>
          </v-alert>
        </v-col>
        <v-col cols="6">
          <v-alert
            dense
            text
            type="info"
            :icon="icons.mdiWallet"
            border="right"
          >
            <div>All-time profit</div>
            <h3 class="mt-2">
              {{ referralInfo.profit }}
            </h3>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-alert
            dense
            text
            type="warning"
            :icon="icons.mdiWallet"
            border="right"
          >
            <div>Referral link</div>
            <h4
              class="mt-2"
            >
              <span class="mr-2">{{ referralInfo.referral_link }}</span>
              <CopyTextBtn
                :text="referralInfo.referral_link"
              ></CopyTextBtn>
            </h4>
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title>Referrals list</v-card-title>
    <v-card-text>
      <v-data-table
        hide-default-footer
        :mobile-breakpoint="0"
        loading-text="Loading referrals ..."
        :loading="loading"
        :items="referralList"
        :headers="tableHeaders"
        :server-items-length="total"
        :options.sync="tableOptions"
        :footer-props="{
          'items-per-page-options': pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }"
      >
        <template v-slot:item.created_at="{ item, value }">
          <slot>
            <span>{{ value.slice(0,value.length-7).split("T").join(" ") }}</span>
          </slot>
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import SettingsApi from '@core/api/settings'
import {
  mdiBrightnessPercent,
  mdiWallet,
} from '@mdi/js'
import pick from 'lodash/pick'
import CopyTextBtn from '@/components/CopyTextBtn.vue'
import TableFooter from '@/components/TableFooter.vue'

export default {
  components: {
    CopyTextBtn,
    TableFooter,
  },
  data() {
    return {
      loading: false,
      referralInfo: [],
      referralList: [],
      icons: {
        mdiBrightnessPercent,
        mdiWallet,
      },
      tableHeaders: [
        {
          text: 'Email',
          value: 'email',
          sortable: false,
        },
        {
          text: 'Created at',
          value: 'created_at',
          sortable: false,
        }],
      tableOptions: {
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.itemsPerPage ? +this.$route.query.itemsPerPage : localStorage.getItem('itemsPerPage') || 50,
      },
      total: 50,
      pages: [50, 100, 200],
    }
  },
  watch: {
    tableOptions: {
      handler(value) {
        const query = pick(value, ['page'])

        query.limit = value.itemsPerPage

        this.fetchReferralList(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },
  async mounted() {
    this.loading = true
    const { data } = await SettingsApi.Referral.getStatistic()
    this.referralInfo = data
    this.fetchReferralList({ limit: this.tableOptions.itemsPerPage, page: this.tableOptions.page })
    this.loading = false
  },
  methods: {
    async fetchReferralList(query) {
      this.loading = true
      const { data } = await SettingsApi.Referral.getReferralList(query)
      this.referralList = data.referral_list
      this.total = data.total
      this.loading = false
    },
  },
}
</script>
